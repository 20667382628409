import Axios from "axios";
import jwtDecode from "jwt-decode";
import config from "../config.json";

import logger from "./logService";

const apiEndPoint = `${config.serverURLSSL}api-token-auth/`;

Axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) logger.log(error);
  return Promise.reject(error);
});

function login(username, password) {
  try {
    const x = Axios.post(apiEndPoint, { username, password });
    return x;
  } catch (error) {
    console.log("status");
  }
}

function logout() {
  localStorage.removeItem("jwt");
}

function getCurrentUser() {
  try {
    const token = localStorage.getItem("jwt");
    return jwtDecode(token);
  } catch (error) {
    return null;
  }
}

function getToken() {
  try {
    const token = localStorage.getItem("jwt");
    return token;
  } catch (error) {
    return null;
  }
}

export default {
  login,
  logout,
  getCurrentUser,
  getToken,
};
