import React, { useState, useEffect } from "react";
import Config from "../../../config.json";

import { Paper, Container, Grid, makeStyles } from "@material-ui/core";

import ArticleTabs from "./ArticleTabs";

export default function ArticleView(props) {
  const articleprops = props.location.state.article;

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper style={{ padding: 20 }}>
              <ArticleTabs article={articleprops} />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
