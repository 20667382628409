import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import {
  AddBox,
  DeleteSharp,
  Edit,
  Search,
  FirstPage,
  NavigateNext,
  SkipNext,
  ArrowBackIos,
  Clear,
  ArrowDownward,
  Check,
} from "@material-ui/icons";
import http from "../services/UpdateTableActions";
import { updateRow } from "../services/UpdateTableActions";

import tableDecoration from "./tableDecoration";

export default function MaterialTableLittle({ title = null, dataShow }) {
  let columnsTab = {};
  switch (title) {
    case "User Experience":
      columnsTab = tableDecoration.expCols;
      break;
    case "User Skills":
      columnsTab = tableDecoration.skillsCols;
      break;
    case "User languages":
      columnsTab = tableDecoration.languagesCols;
      break;

    default:
      columnsTab = [];
      break;
  }
  const [table, setTable] = useState({
    columns: columnsTab,
    data: dataShow,
  });

  useEffect(() => {
    setTable({
      columns: columnsTab,
      data: dataShow,
    });
  }, [dataShow]);

  return (
    <MaterialTable
      title={title}
      columns={table.columns}
      data={table.data}
      icons={{
        Add: AddBox,
        Delete: DeleteSharp,
        Edit: Edit,
        Search: Search,
        FirstPage: FirstPage,
        NextPage: NavigateNext,
        LastPage: SkipNext,
        PreviousPage: ArrowBackIos,
        Clear: Clear,
        SortArrow: ArrowDownward,
        Check: Check,
      }}
      editable={{
        onRowAdd: (newData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              setTable((prevState) => {
                const data = [...prevState.data];
                data.push(newData);
                return { ...prevState, data };
              });
            }, 600);
          }),
        onRowUpdate: (newData, oldData) =>
          updateRow(newData, oldData, setTable),
        onRowDelete: (oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              setTable((prevState) => {
                const data = [...prevState.data];
                data.splice(data.indexOf(oldData), 1);
                return { ...prevState, data };
              });
            }, 600);
          }),
      }}
    />
  );
}
