import React, { useState, useEffect, useContext } from "react";
import MaterialTable from "material-table";

import tableDecoration from "./tableDecoration";
import http from "../services/httpService";
import config from "../config.json";
import UserContext from "../Context/UserContext";
import { updateRow } from "../services/UpdateTableActions";

import {
  AddBox,
  DeleteSharp,
  Edit,
  Search,
  FirstPage,
  NavigateNext,
  SkipNext,
  ArrowBackIos,
  Clear,
  ArrowDownward,
  Check,
  Visibility,
  ChevronLeft,
  ChevronRight,
  DeleteOutline,
  FilterList,
  LastPage,
  Remove,
  SaveAlt,
  ViewColumn,
} from "@material-ui/icons";

export default function MaterialTableComponent({
  title = "my Title",
  data = "users",
  location,
  ...rest
}) {
  const CurrentUser = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [articles, setArticles] = useState([]);
  const [posts, setPosts] = useState([]);
  const [fetchId, setFestchId] = useState(0);
  const [table, setTable] = useState({
    columns: [],
    data: [],
  });

  console.log("mat table");
  console.log("CUrrent user", CurrentUser);
  console.log("CUrrent user name", CurrentUser.name);
  useEffect(() => {
    const getUsers = async () => {
      setLoading(true);
      const x = await http.getUsers();
      setUsers(x.data);

      let tempTable = {
        columns: tableDecoration.usersCols,
        data: x.data,
      };
      setTable(tempTable);
      setLoading(false);
    };

    const getArticles = async () => {
      setLoading(true);
      const x = await http.getArticles();
      setArticles(x.data.results);

      let tempTable = {
        columns: tableDecoration.articlesCols,
        data: x.data.results,
      };
      setTable(tempTable);
      setLoading(false);
    };

    const getPosts = async () => {
      setLoading(true);
      const { data: posts } = await http.getPosts();
      setPosts(posts.results);
      let tempTable = {
        columns: tableDecoration.postsCols,
        data: posts.results,
      };
      setTable(tempTable);
      setLoading(false);

      let arrayOfUsers = await http.getAuthorsFromArray(
        posts.results,
        "author_id"
      );

      const arrayOfIDs = arrayOfUsers[0];
      const finalResult = posts.results.map((e) => {
        const index = arrayOfIDs.indexOf(e.author_id);
        console.log(index);
        e["auth"] = arrayOfUsers[1][index].data;
        return e;
      });

      setPosts(finalResult);
      tempTable = {
        columns: tableDecoration.postsCols,
        data: finalResult,
      };
      setTable(tempTable);
      setLoading(false);
    };

    switch (data) {
      case "users":
        getUsers();
        break;
      case "articles":
        getArticles();
        break;
      case "posts":
        getPosts();
        break;
      default:
        break;
    }

    return () => {};
  }, [fetchId, data]);

  return (
    <MaterialTable
      options={{
        exportButton: true,
      }}
      title={title}
      // columns={tableDecoration.usersCols}
      columns={table.columns}
      data={table.data}
      // data={(query) =>
      //   new Promise((resolve, reject) => {
      //     let url = "http://localhost:8000/api/users/?";
      //     url += "page_size=" + query.pageSize;
      //     url += "&page=" + (query.page + 1);
      //     fetch(url)
      //       .then((response) => response.json())
      //       .then((result) => {
      //         setUsers(result.data);
      //         resolve({
      //           data: result.results,
      //           page: 1,
      //           totalCount: result.count,
      //         });
      //         setLoading(false);
      //       });
      //   })
      // }
      icons={{
        Add: AddBox,
        Delete: DeleteOutline,
        Edit: Edit,
        Search: Search,
        FirstPage: FirstPage,
        NextPage: NavigateNext,
        LastPage: LastPage,
        PreviousPage: ChevronLeft,
        Clear: Clear,
        SortArrow: ArrowDownward,
        Check: Check,
        DetailPanel: ChevronRight,
        Export: SaveAlt,
        Filter: FilterList,
        ResetSearch: Clear,
        ThirdStateCheck: Remove,
        ViewColumn: ViewColumn,
      }}
      isLoading={loading}
      actions={[
        {
          icon: () => <Visibility />,
          tooltip: "View User",
          onClick: (event, rowData) => {
            if (data === "users") {
              const userId = rowData.url.substring(
                `${config.serverURL}/Users`.length,
                rowData.url.length - 1
              );
              rest.history.push({
                pathname: `/userView/${userId}`,
                state: { user: rowData },
              });
            } else if (data === "posts") {
              const postId = rowData.url.substring(
                `${config.serverURL}/posts`.length,
                rowData.url.length - 1
              );
              rest.history.push({
                pathname: `/postView/${postId}`,
                state: { post: rowData },
              });
            } else if (data === "articles") {
              const articleId = rowData.url.substring(
                `${config.serverURL}/articles`.length,
                rowData.url.length - 1
              );
              rest.history.push({
                pathname: `/articleView/${articleId}`,
                state: { article: rowData },
              });
            }
          },
        },
      ]}
      editable={{
        onRowAdd: (newData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              setTable((prevState) => {
                const data = [...prevState.data];
                data.push(newData);
                return { ...prevState, data };
              });
            }, 600);
          }),
        onRowUpdate: (newData, oldData) =>
          updateRow(newData, oldData, setTable),
        onRowDelete: (oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              setTable((prevState) => {
                const data = [...prevState.data];
                data.splice(data.indexOf(oldData), 1);
                return { ...prevState, data };
              });
            }, 600);
          }),
      }}
    />
  );
}
