import React from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import { PeopleAlt, PersonAdd, Assignment, ArtTrack, PostAdd, PlaylistAdd } from "@material-ui/icons";
// core components
import Danger from "./readyToUse/components/Typography/Danger.js";
import Card from "./readyToUse/components/Card/Card.js";
import CardHeader from "./readyToUse/components/Card/CardHeader.js";
import CardIcon from "./readyToUse/components/Card/CardIcon.js";
import CardFooter from "./readyToUse/components/Card/CardFooter.js";

import styles from "./readyToUse/assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function CardGeneric({
  cardType,
  cardTitle,
  cardSubTitle,
  cardInfo,
  InfoIcon,
  cardIcon,
}) {
  const classes = useStyles();
  const InfoIconSelected =
    InfoIcon === "DateRange"
      ? DateRange
      : InfoIcon === "Cloud"
      ? Cloud
      : InfoIcon === "Code"
      ? Code
      : InfoIcon === "BugReport"
      ? BugReport
      : InfoIcon === "Accessibility"
      ? Accessibility
      : InfoIcon === "AccessTime"
      ? AccessTime
      : InfoIcon === "ArrowUpward"
      ? ArrowUpward
      : InfoIcon === "Update"
      ? Update
      : InfoIcon === "LocalOffer"
      ? LocalOffer
      : InfoIcon === "Warning"
      ? Warning
      : InfoIcon === "PersonAdd"
      ? PersonAdd
      : InfoIcon === "articleAdd"
      ? PostAdd
      : InfoIcon === "postAdd"
      ? PlaylistAdd
      : Cloud;

  const CardIconSelected =
    cardIcon === "DateRange"
      ? DateRange
      : cardIcon === "Cloud"
      ? Cloud
      : cardIcon === "Code"
      ? Code
      : cardIcon === "BugReport"
      ? BugReport
      : cardIcon === "Accessibility"
      ? Accessibility
      : cardIcon === "AccessTime"
      ? AccessTime
      : cardIcon === "ArrowUpward"
      ? ArrowUpward
      : cardIcon === "Update"
      ? Update
      : cardIcon === "LocalOffer"
      ? LocalOffer
      : cardIcon === "Warning"
      ? Warning
      : cardIcon === "Users"
      ? PeopleAlt
      : cardIcon === "PersonAdd"
      ? PersonAdd
      : cardIcon === "post"
      ? ArtTrack
      : cardIcon === "article"
      ? Assignment
      : Cloud;
  return (
    <Card>
      <CardHeader color={cardType ? cardType : "warning"} stats icon>
        <CardIcon color={cardType ? cardType : "warning"}>
          <CardIconSelected />
        </CardIcon>
        <p className={classes.cardCategory}>{cardTitle ? cardTitle : null}</p>
        <h3 className={classes.cardTitle}>
          {cardSubTitle ? cardSubTitle : null}
        </h3>
      </CardHeader>
      <CardFooter stats>
        <div className={classes.stats}>
          <InfoIconSelected />
          {cardInfo ? cardInfo : null}
        </div>
      </CardFooter>
    </Card>
  );
}
