import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useSnackbar, SnackbarProvider } from "notistack";
import auth from "../../services/authService";
import logger from "../../services/logService";
import { Redirect } from "react-router-dom";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://rotate-pro.com">
        Rotate-Pro
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const MyButton = ({ username, password, ...rest }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleError = (message) => {
    enqueueSnackbar(message, {
      variant: "warning",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };

  const doSubmit = async (e) => {
    try {
      const { pathComingFrom } = rest;
      const { data: jwt } = await auth.login(username, password);
      localStorage.setItem("jwt", jwt.token);
      window.location = pathComingFrom ? pathComingFrom : "/";
    } catch (error) {
      if (error.response.status === 400)
        handleError("Invalid username or password");
    }
  };

  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      // className={classes.submit}
      onClick={(e) => doSubmit(e)}
    >
      Sign In
    </Button>
  );
};

export default function SignIn(props) {
  const classes = useStyles();
  const { state } = props.location;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const pathComingFrom = state ? state.from.pathname : null;

  if (auth.getCurrentUser()) return <Redirect to="/" />;
  return (
    <SnackbarProvider maxSnack={3}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <MyButton
            username={username}
            password={password}
            pathComingFrom={pathComingFrom}
          />

          {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </SnackbarProvider>
  );
}
