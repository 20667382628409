import React from "react";

import { Paper, Container, Grid } from "@material-ui/core";
import PostTabs from "./PostTabs";

export default function PostView(props) {
  const postprops = props.location.state.post;

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper style={{ padding: 20 }}>
              <PostTabs post={postprops} />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
