import Axios from "axios";
import config from "../config.json";
import logger from "./logService";
import auth from "./authService";

Axios.defaults.headers.common["authorization"] = `JWT ${auth.getToken()}`;

Axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) logger.log(error);
  return Promise.reject(error);
});

async function getUsers() {
  const x = await Axios.get(config.serverURLSSL + "Users/");
  return x;
}

// async function getUsers() {
//   const x = await Axios.get(config.serverURLSSL + "users/?page=1&page_size=5");
//   return x;
// }

async function getUserInfo(id) {
  const x = await Axios.get(`${config.serverURLSSL}userInfo/?user_id=${id}`);
  return x;
}

async function getUsersCount() {
  const { data } = await Axios.get(`${config.serverURLSSL}users/?page_size=1`);
  return data.count;
}

async function getUsersCountAfterDate(date) {
  const { data } = await Axios.get(
    `${
      config.serverURLSSL
    }users/?page_size=1&date_joined__gte=${date.toISOString()}`
  );
  return data.count;
}

async function getUserSkills(id) {
  const x = await Axios.get(`${config.serverURLSSL}skill/?user_id=${id}`);
  return x;
}

async function getUserExp(id) {
  const x = await Axios.get(
    `${config.serverURLSSL}professsional_exp/?user_id=${id}`
  );
  return x;
}

async function getUserLanguages(id) {
  const x = await Axios.get(`${config.serverURLSSL}habla/?user_id=${id}`);
  return x;
}

// async function getArticles() {
//   const x = await Axios.get(config.serverURLSSL + "articles/");
//   return x;
// }

async function getArticles() {
  const x = await Axios.get(
    config.serverURLSSL + "articles/?page=1&page_size=50"
  );
  return x;
}

async function getArticlesCountAfterDate(date) {
  const { data } = await Axios.get(
    `${
      config.serverURLSSL
    }articles/?page=1&page_size=1&date_created__gte=${date.toISOString()}`
  );
  return data.count;
}

async function getArticlesCount() {
  const { data } = await Axios.get(
    `${config.serverURLSSL}articles/?page=1&page_size=1`
  );
  return data.count;
}

async function getArticlesComments(id) {
  const x = await Axios.get(
    `${config.serverURLSSL}comment_article/?article_id=${id}`
  );
  return x;
}

async function getArticlesLikes(id) {
  const x = await Axios.get(
    `${config.serverURLSSL}likes_articles20/?article_id=${id}`
  );
  return x;
}

async function getPostsComments(id) {
  const x = await Axios.get(`${config.serverURLSSL}comments/?post_id=${id}`);
  return x;
}

async function getPostsLikes(id) {
  const x = await Axios.get(`${config.serverURLSSL}likes/?post_id=/${id}`);
  return x;
}

// async function getPosts() {
//   const x = await Axios.get(config.serverURLSSL + "posts/");
//   return x;
// }

async function getPosts() {
  const x = await Axios.get(
    config.serverURLSSL + "post20/?page=1&page_size=50"
  );
  return x;
}

async function getPostsCount() {
  const x = await Axios.get(config.serverURLSSL + `post20/?page_size=1`);
  return x.data.count;
}

async function getPostsCountAfterDate(date) {
  const { data } = await Axios.get(
    `${
      config.serverURLSSL
    }post/?page_size=1&date_created__gte=${date.toISOString()}`
  );
  return data.count;
}

async function updateUser(url, data) {
  const newData = {
    email: data.email,
    first_name: data.first_name,
    last_name: data.last_name,
    is_active: data.is_active,
    is_staff: data.is_staff,
    is_superuser: data.is_superuser,
    username: data.username,
  };
  const x = await Axios.patch(url, newData);
}

async function updateRow(url, data) {
  const x = await Axios.patch(url, data);
  return x.data;
}

async function updateUserInfo(url, data) {
  const x = await Axios.patch(url, data);
}

async function updateUserSkill(url, data) {
  const newData = {
    name: data.name,
  };
  const x = await Axios.patch(url, data);
}

async function updateUserLang(url, data) {
  const newData = {
    name: data.name,
  };
  const x = await Axios.patch(url, data);
}

async function getAuthorsFromArray(array, tag) {
  let arrayOfPromises = [];
  let arrayOfIDs = [];
  const APIEndpoint = `${config.serverURLSSL}Users/`;

  array.forEach((post) => {
    const request = Axios.get(`${APIEndpoint}${post[tag]}/`);
    if (!arrayOfIDs.includes(post[tag])) {
      arrayOfIDs.push(post[tag]);
      arrayOfPromises.push(request);
    }
  });

  const reponses = await Promise.all(arrayOfPromises);
  return [arrayOfIDs, reponses];
}

async function getAuthorsFromArrayWithLink(array) {
  let arrayOfPromises = [];
  let arrayOfLinks = [];

  array.forEach((element) => {
    const request = Axios.get(element.user_auth);
    if (!arrayOfLinks.includes(element.user_auth)) {
      arrayOfLinks.push(element.user_auth);
      arrayOfPromises.push(request);
    }
  });

  const reponses = await Promise.all(arrayOfPromises);

  return [arrayOfLinks, reponses];
}

export default {
  getUsers,
  getUsersCount,
  getUsersCountAfterDate,
  getUserInfo,
  updateUser,
  updateUserInfo,
  updateUserSkill,
  updateUserLang,
  updateRow,
  getArticles,
  getArticlesCount,
  getArticlesCountAfterDate,
  getPosts,
  getUserSkills,
  getUserExp,
  getUserLanguages,
  getArticlesComments,
  getPostsComments,
  getPostsLikes,
  getArticlesLikes,
  getAuthorsFromArray,
  getAuthorsFromArrayWithLink,
  getPostsCount,
  getPostsCountAfterDate,
};
