import React, { useState, useEffect } from "react";
import http from "../../../services/httpService";
import MaterialTableLittle from "../../../component/MaterialTableLittle";
import { makeStyles } from "@material-ui/core";

export default function UserTabFive({ userInfo }) {
  const [languages, setLanguages] = useState([]);
  useEffect(() => {
    const getlanguages = async () => {
      const x = await http.getUserLanguages(userInfo.user_id);
      console.log("user languages", x);
      setLanguages(x.data.results);
    };

    getlanguages();
  }, [userInfo]);

  console.log(languages);
  return (
    <React.Fragment>
      <MaterialTableLittle title="User languages" dataShow={languages} />
    </React.Fragment>
  );
}
