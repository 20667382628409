import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../services/authService";

export default function ProtectedRoute({ Component, render, path, ...rest }) {
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        if (!auth.getCurrentUser())
          return (
            <Redirect
              to={{ pathname: "Login", state: { from: rest.location } }}
            />
          );
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
}
