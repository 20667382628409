import React, { useState, useEffect } from "react";
import http from "../../../services/httpService";
import MaterialTableLittle from "../../../component/MaterialTableLittle";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  inputStyle: { marginBottom: 10 },
  titleStyle: { marginBottom: 20, color: "#3f51b5" },
}));

export default function UserTabFour({ userInfo }) {
  const [experience, setExperience] = useState([]);
  useEffect(() => {
    const getexperience = async () => {
      const x = await http.getUserExp(userInfo.user_id);
      console.log("user experience", x);
      setExperience(x.data.results);
    };

    getexperience();
  }, [userInfo]);

  console.log(experience);
  return (
    <React.Fragment>
      <MaterialTableLittle title="User Experience" dataShow={experience} />
    </React.Fragment>
  );
}
