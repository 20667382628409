import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "./readyToUse/components/Card/Card.js";
import CardHeader from "./readyToUse/components/Card/CardHeader.js";
import CardFooter from "./readyToUse/components/Card/CardFooter.js";
import { VictoryPie } from "victory";
import CardIcon from "./readyToUse/components/Card/CardIcon.js";

export default function CardPieChartPageVisits({ data }) {
  const [dataTreated, setdataTreated] = useState([]);

  useEffect(() => {
    const treat = data.reduce(
      (acc, obj) => {
        if (obj.link.includes("Newsfeed") || obj.link == "/") {
          if (!acc[0]) {
            acc[0] = {};
            acc[0] = { x: "NewsFeed", y: 1 };
          } else acc[0] = { x: "NewsFeed", y: acc[0].y + 1 };
        } else if (obj.link.includes("Profile")) {
          if (!acc[1]) {
            acc[1] = {};
            acc[1] = { x: "Profiles", y: 1 };
          } else acc[1] = { x: "Profiles", y: acc[1].y + 1 };
        } else if (obj.link.includes("Article")) {
          if (!acc[2]) {
            acc[2] = {};
            acc[2] = { x: "Articles", y: 1 };
          } else acc[2] = { x: "Articles", y: acc[2].y + 1 };
        } else if (obj.link.includes("People")) {
          if (!acc[3]) {
            acc[3] = {};
            acc[3] = { x: "People", y: 1 };
          } else acc[3] = { x: "People", y: acc[3].y + 1 };
        }

        return acc;
      },

      []
    );
    setdataTreated(treat);
    console.log(treat);
  }, [data]);

  return (
    <Card>
      <CardHeader stats icon>
        <CardIcon color="success">
          <div style={{ color: "#FFF", fontWeight: "bold" }}>Pages visited</div>
        </CardIcon>
        <VictoryPie
          padding={{ left: 70, top: 50, right: 100, bottom: 50 }}
          // theme={VictoryTheme.grayscale}
          colorScale="blue"
          data={dataTreated}
          style={{
            labels: { fontSize: 15, fill: "#1a237e" },
          }}
        />
      </CardHeader>
      <CardFooter>
        {dataTreated.map((obj, key) => (
          <div key={key} style={{ textAlign: "center", color: "blue" }}>
            <div style={{ color: "#1a237e", fontWeight: "bold" }}>{obj.x}</div>{" "}
            {obj.y}
          </div>
        ))}
      </CardFooter>
    </Card>
  );
}
