import React, { useState, useEffect } from "react";
import Config from "../../../config.json";

import Image from "material-ui-image";
import { Paper, Container, Grid, makeStyles } from "@material-ui/core";

import http from "../../../services/httpService";
import UserFormTabs from "./UserFormTabs";

const linkLength = `${Config.serverURL}Users/`.length;

export default function UserView(props) {
  const userprops = props.location.state.user;
  const user_id = userprops.url.substring(linkLength, userprops.url.length - 1);

  const [userInfo, setUserInfo] = useState({});
  const [newUserInfo, setNewUserInfo] = useState({});

  const useStyles = makeStyles((theme) => ({
    ContainerStyle: {
      backgroundImage: userInfo.bg ? `url(${userInfo.bg})` : null,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      paddingTop: 40,
      paddingBottom: 20,
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    const getUserInfo = async () => {
      const x = await http.getUserInfo(user_id);
      setUserInfo(x.data.results[0]);
      setNewUserInfo({
        country: userInfo.country,
        summary: userInfo.summary,
        tel: userInfo.tel,
      });
    };

    getUserInfo();
  }, [props.location.pathname]);

  return (
    <React.Fragment>
      <Container maxWidth="lg" className={classes.ContainerStyle}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={8}>
            <Paper style={{ padding: 20 }}>
              <UserFormTabs
                userAuth={props.location.state.user}
                location={props.location}
                userInfo={userInfo}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <Paper style={{ padding: 20 }}>
              <div>
                <Image
                  src={
                    userInfo.photo
                      ? userInfo.photo
                      : "https://medgoldresources.com/wp-content/uploads/2018/02/avatar-placeholder.gif"
                  }
                />
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
