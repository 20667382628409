import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import {
  AddBox,
  DeleteSharp,
  Edit,
  Search,
  FirstPage,
  NavigateNext,
  SkipNext,
  ArrowBackIos,
  Clear,
  ArrowDownward,
  Check,
} from "@material-ui/icons";

import http from "../services/httpService";
import tableDecoration from "./tableDecoration";
import config from "../config.json";

export default function MTPostsCL({ title = null, post }) {
  let columnsTab = {};
  switch (title) {
    case "Comments":
      columnsTab = tableDecoration.commentsArticleCols;
      break;
    case "Likes":
      columnsTab = tableDecoration.likesArticleCols;
      break;

    default:
      columnsTab = [];
      break;
  }
  const [table, setTable] = useState({
    columns: columnsTab,
    data: [],
  });

  useEffect(() => {
    const urlLength = `${config.serverURL}posts/`.length;
    const article_id = post.url.substring(urlLength, post.url.length - 1);
    const getComments = async () => {
      const { data: comments } = await http.getPostsComments(article_id);
      setTable({
        columns: columnsTab,
        data: comments,
      });

      const arrayOfUsers = await http.getAuthorsFromArrayWithLink(comments);
      console.log(arrayOfUsers);
      const arrayOfLinks = arrayOfUsers[0];
      const finalResult = comments.map((e) => {
        const index = arrayOfLinks.indexOf(e.user_auth);
        e["auth"] = arrayOfUsers[1][index].data;
        return e;
      });

      setTable({
        columns: columnsTab,
        data: finalResult,
      });
    };

    const getLikes = async () => {
      const { data: likes } = await http.getPostsLikes(article_id);

      setTable({
        columns: columnsTab,
        data: likes.results,
      });
      let arrayOfUsers = await http.getAuthorsFromArray(
        likes.results,
        "user_id"
      );
      console.log(arrayOfUsers);
      const arrayOfIDs = arrayOfUsers[0];
      const finalResult = likes.results.map((e) => {
        const index = arrayOfIDs.indexOf(e.user_id);
        console.log(index);
        e["auth"] = arrayOfUsers[1][index].data;
        return e;
      });

      setTable({
        columns: tableDecoration.likesArticleCols,
        data: finalResult,
      });
    };

    switch (title) {
      case "Comments":
        getComments();
        break;
      case "Likes":
        getLikes();
        break;

      default:
        break;
    }
  }, [title]);

  return (
    <MaterialTable
      title={title}
      columns={table.columns}
      data={table.data}
      icons={{
        Add: AddBox,
        Delete: DeleteSharp,
        Edit: Edit,
        Search: Search,
        FirstPage: FirstPage,
        NextPage: NavigateNext,
        LastPage: SkipNext,
        PreviousPage: ArrowBackIos,
        Clear: Clear,
        SortArrow: ArrowDownward,
        Check: Check,
      }}
      editable={{
        onRowAdd: (newData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              setTable((prevState) => {
                const data = [...prevState.data];
                data.push(newData);
                return { ...prevState, data };
              });
            }, 600);
          }),
        // onRowUpdate: (newData, oldData) =>
        //   new Promise((resolve) => {
        //     setTimeout(() => {
        //       resolve();
        //       if (oldData) {
        //         setTable((prevState) => {
        //           const data = [...prevState.data];
        //           data[data.indexOf(oldData)] = newData;
        //           return { ...prevState, data };
        //         });
        //       }
        //     }, 600);
        //   }),
        onRowDelete: (oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              setTable((prevState) => {
                const data = [...prevState.data];
                data.splice(data.indexOf(oldData), 1);
                return { ...prevState, data };
              });
            }, 600);
          }),
      }}
    />
  );
}
