import React, { useState, useEffect } from "react";
import http from "../../../services/httpService";
import MaterialTableLittle from "../../../component/MaterialTableLittle";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  inputStyle: { marginBottom: 10 },
  titleStyle: { marginBottom: 20, color: "#3f51b5" },
}));

export default function UserTabThree({ userInfo }) {
  const [skills, setSkills] = useState([]);
  useEffect(() => {
    const getSkills = async () => {
      const x = await http.getUserSkills(userInfo.user_id);
      console.log("user skills", x);
      setSkills(x.data);
    };

    getSkills();
  }, [userInfo]);

  console.log(skills);
  return (
    <React.Fragment>
      <MaterialTableLittle title="User Skills" dataShow={skills} />
    </React.Fragment>
  );
}
