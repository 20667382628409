import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader/ListSubheader";
import {
  Dashboard,
  PeopleAltOutlined,
  Description,
  AllInbox,
  Assignment,
} from "@material-ui/icons";
import { NavLink } from "react-router-dom";

export const mainListItems = (pathname) => {
  return (
    <React.Fragment>
      <ListItem button component={NavLink} to="/" selected={pathname === "/"}>
        <ListItemIcon>
          <Dashboard style={{ color: "white" }} />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>

      <ListItem
        button
        component={NavLink}
        to="/Users"
        selected={pathname === "/Users"}
      >
        <ListItemIcon>
          <PeopleAltOutlined style={{ color: "white" }} />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </ListItem>

      <ListItem
        button
        component={NavLink}
        to="/Articles"
        selected={pathname === "/Articles"}
      >
        <ListItemIcon>
          <Description style={{ color: "white" }} />
        </ListItemIcon>
        <ListItemText primary="Articles" />
      </ListItem>

      <ListItem
        button
        component={NavLink}
        to="/Posts"
        selected={pathname === "/Posts"}
      >
        <ListItemIcon>
          <AllInbox style={{ color: "white" }} />
        </ListItemIcon>
        <ListItemText primary="Posts" />
      </ListItem>

      {/* <ListItem button>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Customers" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Reports" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Integrations" />
    </ListItem> */}
    </React.Fragment>
  );
};

export const secondaryListItems = (pathname) => {
  return (
    <React.Fragment>
      <ListItem
        button
        component={NavLink}
        to="/Statistics"
        selected={pathname === "/Statistics"}
      >
        <ListItemIcon>
          <Assignment style={{ color: "white" }} />
        </ListItemIcon>
        <ListItemText primary="Statistics" />
      </ListItem>

      {/* <ListSubheader inset>Saved reports</ListSubheader>
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Current month" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Last quarter" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Year-end sale" />
      </ListItem> */}
    </React.Fragment>
  );
};
