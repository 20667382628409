import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Image from "material-ui-image";
import {
  FormControlLabel,
  Switch,
  Typography,
  Button,
  makeStyles,
  TextField,
} from "@material-ui/core";
import http from "../../../services/httpService";

const useStyles = makeStyles((theme) => ({
  inputStyle: { marginBottom: 10 },
  titleStyle: { marginBottom: 20, color: "#3f51b5" },
}));

export default function UserTabTwo({ userInfo }) {
  const [newUserInfo, setNewUserInfo] = useState({
    tel: userInfo.tel,
    summary: userInfo.summary,
    country: userInfo.country,
  });
  const classes = useStyles();

  const userSchema = {
    tel: Joi.string().required(),
    summary: Joi.string().required(),
    country: Joi.string().required(),
  };

  const handleChange = (e) => {
    setNewUserInfo({ ...newUserInfo, [e.target.name]: e.target.value });
  };

  const Validate = () => {
    console.log(Joi.validate(newUserInfo, userSchema));
  };

  const handleSubmit = () => {
    Validate();
    http.updateUserInfo(userInfo.url, newUserInfo);
  };

  return (
    <React.Fragment>
      <Typography className={classes.titleStyle} variant="h5" component="h5">
        User Info
      </Typography>
      <form>
        <TextField
          className={classes.inputStyle}
          name="tel"
          fullWidth
          error={false}
          id="outlined-error-helper-text"
          label="tel"
          defaultValue={newUserInfo.tel}
          helperText="Incorrect entry."
          variant="outlined"
          onChange={(event) => handleChange(event)}
          value={newUserInfo.tel}
        />
        <TextField
          className={classes.inputStyle}
          name="summary"
          fullWidth
          error={false}
          id="outlined-error-helper-text"
          label="Summary"
          defaultValue={newUserInfo.summary}
          helperText="Incorrect entry."
          variant="outlined"
          onChange={(event) => handleChange(event)}
          value={newUserInfo.summary}
        />
        <TextField
          className={classes.inputStyle}
          name="country"
          fullWidth
          error={false}
          id="outlined-error-helper-text"
          label="Country"
          defaultValue={newUserInfo.country}
          helperText="Incorrect entry."
          variant="outlined"
          onChange={(event) => handleChange(event)}
          value={newUserInfo.country}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => handleSubmit()}
        >
          Save
        </Button>
      </form>
    </React.Fragment>
  );
}
