import React from "react";
import {
  Typography,
  Button,
  TextField,
  makeStyles,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import http from "../../../services/httpService";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  inputStyle: { marginBottom: 10 },
  titleStyle: { marginBottom: 20, color: "#3f51b5" },
}));

function PostEditForm({ post }) {
  const classes = useStyles();
  const [newPost, setNewPost] = useState(post);

  const handleSubmit = () => {
    http.updateRow(post.url, newPost);
  };
  return (
    <React.Fragment>
      <Typography className={classes.titleStyle} variant="h5" component="h5">
        Base Info
      </Typography>
      <form>
        <TextField
          className={classes.inputStyle}
          name="author"
          fullWidth
          error={false}
          id="outlined-error-helper-text"
          label="Author name"
          helperText="Incorrect entry."
          variant="outlined"
          defaultValue={
            post.auth ? `${post.auth.first_name} ${post.auth.last_name}` : null
          }
          disabled
        />
        <TextField
          className={classes.inputStyle}
          name="content"
          fullWidth
          error={false}
          id="outlined-error-helper-text"
          label="Content"
          helperText="Incorrect entry."
          variant="outlined"
          defaultValue={post.content}
          onChange={(event) =>
            setNewPost({ ...newPost, content: event.target.value })
          }
          value={newPost.content}
        />
        <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
        <Select
          className={classes.inputStyle}
          fullWidth
          variant="outlined"
          labelId="type"
          id="type"
          defaultValue={post.media_type}
          value={newPost.media_type}
          onChange={(event) =>
            setNewPost({ ...newPost, media_type: event.target.value })
          }
          label="Type"
        >
          <MenuItem value="simple">Simple</MenuItem>
          <MenuItem value="image">Image</MenuItem>
          <MenuItem value="video">Video</MenuItem>
          <MenuItem value="youtube">YouTube</MenuItem>
        </Select>

        <Button
          variant="contained"
          color="primary"
          onClick={(e) => handleSubmit()}
        >
          Save
        </Button>
      </form>
    </React.Fragment>
  );
}

export default PostEditForm;
