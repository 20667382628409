import React, { useState, useEffect } from "react";
import CardGeneric from "../../component/CardGeneric";
import GridItem from "../../component/readyToUse/components/Grid/GridItem.js";
import GridContainer from "../../component/readyToUse/components/Grid/GridContainer.js";
import httpService from "../../services/httpService";

const date = new Date();
const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
export default function DashWelc(props) {
  const [usersCount, setUsersCount] = useState({});
  const [articlesCount, setArticlesCount] = useState({});
  const [postsCount, setPostsCount] = useState({});

  useEffect(() => {
    const populateData = async () => {
      const requestsArray = [
        httpService.getUsersCount(),
        httpService.getUsersCountAfterDate(firstDay),
        httpService.getArticlesCount(),
        httpService.getArticlesCountAfterDate(firstDay),
        httpService.getPostsCount(),
        httpService.getPostsCountAfterDate(firstDay),
      ];
      const responses = await Promise.all(requestsArray);
      setUsersCount({ total: responses[0], month: responses[1] });
      setArticlesCount({ total: responses[2], month: responses[3] });
      setPostsCount({ total: responses[4], month: responses[5] });
    };
    populateData();
  }, []);
  console.log(usersCount);
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={4}>
          <CardGeneric
            cardTitle="Users"
            cardSubTitle={`Total ${usersCount.total ? usersCount.total : 0}`}
            cardType="success"
            cardInfo={`This month ${usersCount.month ? usersCount.month : 0}`}
            cardIcon="Users"
            InfoIcon="PersonAdd"
            {...props}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <CardGeneric
            cardTitle="Articles"
            cardSubTitle={`Total ${
              articlesCount.total ? articlesCount.total : 0
            }`}
            cardType="success"
            cardIcon="article"
            InfoIcon="articleAdd"
            cardInfo={`This month ${
              articlesCount.month ? articlesCount.month : 0
            }`}
            {...props}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <CardGeneric
            cardTitle="Posts"
            cardSubTitle={`Total ${postsCount.total ? postsCount.total : 0}`}
            cardType="success"
            cardInfo={`This month ${postsCount.month ? postsCount.month : 0}`}
            cardIcon="post"
            InfoIcon="postAdd"
            {...props}
          />
        </GridItem>
        {/* <GridItem xs={12} sm={6} md={3}>
          <CardGeneric
            cardTitle="Bassem"
            cardSubTitle="subtitle"
            cardType="success"
            cardInfo="myInfo"
            {...props}
          />
        </GridItem> */}
      </GridContainer>
    </div>
  );
}
