import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Image from "material-ui-image";
import {
  FormControlLabel,
  Switch,
  Typography,
  Button,
  makeStyles,
  TextField,
} from "@material-ui/core";
import http from "../../../services/httpService";

const useStyles = makeStyles((theme) => ({
  inputStyle: { marginBottom: 10 },
  titleStyle: { marginBottom: 20, color: "#3f51b5" },
}));

export default function UserTabOne({ userAuth }) {
  const [user, setUser] = useState(userAuth);
  const [newUser, setNewUser] = useState({
    username: userAuth.username,
    email: userAuth.email,
    first_name: userAuth.first_name,
    last_name: userAuth.last_name,
    is_superuser: userAuth.is_superuser,
    is_staff: userAuth.is_staff,
    is_active: userAuth.is_active,
  });
  const classes = useStyles();

  const userSchema = {
    username: Joi.string().required(),
    email: Joi.string()
      .email()
      .required(),
    first_name: Joi.string().required(),
    last_name: Joi.string().required(),
    is_superuser: Joi.boolean().required(),
    is_staff: Joi.boolean().required(),
    is_active: Joi.boolean().required(),
  };

  const handleChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  const Validate = () => {
    console.log(Joi.validate(newUser, userSchema));
  };

  const handleSubmit = async () => {
    Validate();
    const x = await http.updateRow(user.url, newUser)
    setUser(x)
  };

  return (
    <React.Fragment>
      <Typography className={classes.titleStyle} variant="h5" component="h5">
        Base Info
      </Typography>
      <form>
        <TextField
          className={classes.inputStyle}
          name="username"
          fullWidth
          error={false}
          id="outlined-error-helper-text"
          label="Username"
          defaultValue={newUser.username}
          helperText="Incorrect entry."
          variant="outlined"
          onChange={(event) => handleChange(event)}
          value={newUser.username}
        />
        <TextField
          className={classes.inputStyle}
          name="first_name"
          fullWidth
          error={false}
          id="outlined-error-helper-text"
          label="First name"
          defaultValue={newUser.first_name}
          helperText="Incorrect entry."
          variant="outlined"
          onChange={(event) => handleChange(event)}
          value={newUser.first_name}
        />
        <TextField
          className={classes.inputStyle}
          name="last_name"
          fullWidth
          error={false}
          id="outlined-error-helper-text"
          label="Last name"
          defaultValue={newUser.last_name}
          helperText="Incorrect entry."
          variant="outlined"
          onChange={(event) => handleChange(event)}
          value={newUser.last_name}
        />
        <TextField
          className={classes.inputStyle}
          name="email"
          fullWidth
          error={false}
          id="outlined-error-helper-text"
          label="Email"
          defaultValue={newUser.email}
          helperText="Incorrect entry."
          variant="outlined"
          onChange={(event) => handleChange(event)}
          value={newUser.email}
        />
        <FormControlLabel
          value={newUser.is_superuser}
          control={<Switch color="primary" />}
          label="super user"
          labelPlacement="start"
        />
        <FormControlLabel
          value={newUser.is_staff}
          control={<Switch color="primary" />}
          label="Staff"
          labelPlacement="start"
        />
        <FormControlLabel
          value={newUser.is_active}
          control={<Switch color="primary" />}
          label="active"
          labelPlacement="start"
        />

        <Button
          variant="contained"
          color="primary"
          onClick={(e) => handleSubmit()}
        >
          Save
        </Button>
      </form>
    </React.Fragment>
  );
}
