import React, { useState, useEffect } from "react";
import { VictoryPie } from "victory";
import CardGeneric from "../../../component/CardGeneric";
import GridItem from "../../../component/readyToUse/components/Grid/GridItem.js";
import GridContainer from "../../../component/readyToUse/components/Grid/GridContainer.js";
import httpService from "../../../services/httpService";
import CardPieChartFailedLogins from "../../../component/cardPieChartFailedLogins";

import logListServices from "../../../services/logList";
import loginAttemptServices from "../../../services/loginAttempts";
import viewServices from "../../../services/views";
import CardPieChartPageVisits from "../../../component/cardPieChartPageVisits";

const date = new Date();
const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

export default function Statistics(props) {
  const [loginList, setloginList] = useState(0);
  const [loginListMonth, setLoginListMonth] = useState(0);

  const [loginAttempts, setLoginAttempts] = useState(0);
  const [loginAttemptsMonth, setLoginAttemptsMonth] = useState(0);
  const [loginFailed, setloginFailed] = useState([]);

  const [views, setViews] = useState(0);
  const [viewsMonth, setViewsMonth] = useState(0);
  const [viewsList, setViewsList] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const responses = await Promise.all([
        logListServices.getLogList(),
        loginAttemptServices.getLogAttempts(),
        viewServices.getViews(),
      ]);

      console.log(responses);
      setloginList(responses[0].length);
      setLoginListMonth(
        responses[0].filter((el) => el.date_created > firstDay.toISOString())
          .length
      );

      setLoginAttempts(responses[1].length);
      setLoginAttemptsMonth(
        responses[1].filter((el) => el.date_created > firstDay.toISOString())
          .length
      );
      setloginFailed(responses[1]);

      setViews(responses[2].length);
      setViewsMonth(
        responses[2].filter((el) => el.date_created > firstDay.toISOString())
          .length
      );
      setViewsList(responses[2]);
    };
    getData();
  }, []);
  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <CardGeneric
            cardTitle="Logins"
            cardSubTitle={`Total ${loginList ? loginList : 0}`}
            cardType="success"
            cardInfo={`This month ${loginListMonth}`}
            cardIcon="Users"
            InfoIcon="PersonAdd"
            {...props}
          />
        </GridItem>
        {/* <GridItem xs={12} sm={6} md={4}>
          <CardGeneric
            cardTitle="Failed logins"
            cardSubTitle={`Total ${loginAttempts}`}
            cardType="warning"
            cardInfo={`This month ${loginAttemptsMonth}`}
            cardIcon="Users"
            InfoIcon="PersonAdd"
            {...props}
          />
        </GridItem> */}
        <GridItem xs={12} sm={6} md={6}>
          <CardGeneric
            cardTitle="Pages Visits"
            cardSubTitle={`Total ${views}`}
            cardType="info"
            cardInfo={`This month ${viewsMonth}`}
            cardIcon="Users"
            InfoIcon="PersonAdd"
            {...props}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        {/* <GridItem xs={12} sm={6} md={6}>
          <CardPieChartFailedLogins data={loginFailed} />
        </GridItem> */}
        <GridItem xs={12} sm={12} md={6}>
          <CardPieChartPageVisits data={viewsList} />
        </GridItem>
        {/* <GridItem xs={12} sm={6} md={3}>
    <CardGeneric
      cardTitle="Bassem"
      cardSubTitle="subtitle"
      cardType="success"
      cardInfo="myInfo"
      {...props}
    />
  </GridItem> */}
      </GridContainer>
    </React.Fragment>
  );
}
