import React, { useState, useEffect, useContext } from "react";
import logo from "./logo.svg";
import { Route, Switch, Redirect } from "react-router-dom";
import "./App.css";

import Home from "./views/Dashboard/Home";
import SignIn from "./views/login/SignIn";
import NotFound from "./views/NotFound";
import MaterialTableComponent from "./component/MaterialTableComponent";
import UserView from "./views/Dashboard/users/UserView";
import ArticleView from "./views/Dashboard/articles/ArticleView";
import auth from "./services/authService";
import ProtectedRoute from "./component/ProtectedRoute";
import UserContext from "./Context/UserContext";
import PostView from "./views/Dashboard/postRep/PostView";

import DashWelc from "./views/Dashboard/DashWelc";
import Statistics from "./views/Dashboard/statistics/statistics";

function App() {
  const [user, setUser] = useState(auth.getCurrentUser());

  // try {
  //   setJwt(localStorage.getItem("jwt"));
  //   setUser(jwtDecode(jwt));
  // } catch (error) {}

  return (
    <UserContext.Provider value={user}>
      <Switch>
        <Route path="/Login" component={SignIn} />
        {/* <ProtectedRoute
          path="/Admin"
          render={(props) => (
            <Home Component={MaterialTableComponent} {...props} />
          )}
        /> */}
        <ProtectedRoute
          path="/Users"
          render={(props) => {
            return (
              <Home
                Component={MaterialTableComponent}
                title="Users table"
                data="users"
                {...props}
              />
            );
          }}
        />
        <ProtectedRoute
          path="/Users"
          render={(props) => {
            return (
              <Home
                Component={MaterialTableComponent}
                title="Users table"
                data="users"
                {...props}
              />
            );
          }}
        />
        <ProtectedRoute
          path="/Articles"
          render={(props) => (
            <Home
              Component={MaterialTableComponent}
              title="Article table"
              data="articles"
              {...props}
            />
          )}
        />
        <ProtectedRoute
          path="/Posts"
          render={(props) => (
            <Home
              Component={MaterialTableComponent}
              title="Posts table"
              data="posts"
              {...props}
            />
          )}
        />
        <ProtectedRoute
          path="/Statistics"
          render={(props) => (
            <Home
              Component={Statistics}
              title="Statistics table"
              data="Statistics"
              {...props}
            />
          )}
        />
        <ProtectedRoute
          path="/userView/:id"
          render={(props) => <Home Component={UserView} {...props} />}
        />
        <ProtectedRoute
          path="/articleView/:id"
          render={(props) => <Home Component={ArticleView} {...props} />}
        />
        <ProtectedRoute
          path="/postView/:id"
          render={(props) => <Home Component={PostView} {...props} />}
        />
        <ProtectedRoute
          path="/"
          render={(props) => <Home Component={DashWelc} {...props} />}
        />

        <Route path="*" component={NotFound} />
      </Switch>
    </UserContext.Provider>
  );
}

export default App;
