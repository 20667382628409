import React from "react";
import http from "./httpService";

// export const defaultActionUpdate = (newData, oldData) =>
//   new Promise((resolve) => {
//     setTimeout(() => {
//       resolve();
//       if (oldData) {
//         setTable((prevState) => {
//           const data = [...prevState.data];
//           data[data.indexOf(oldData)] = newData;
//           return { ...prevState, data };
//         });
//       }
//     }, 600);
//   });
export const updateRow = (newData, oldData, setTable) =>
  new Promise(async (resolve) => {
    // setTimeout(() => {
    resolve();
    if (oldData) {
      setTable((prevState) => {
        const data = [...prevState.data];
        data[data.indexOf(oldData)] = newData;
        return { ...prevState, data };
      });
    }
    try {
      const response = await http.updateRow(oldData.url, newData);
    } catch (error) {
      if (oldData) {
        setTable((prevState) => {
          const data = [...prevState.data];
          data[data.indexOf(newData)] = oldData;
          return { ...prevState, data };
        });
      }
    }

    // }, 600);
  });

export default {
  updateRow,
};
