import Axios from "axios";
import config from "../config.json";
import logger from "./logService";
import auth from "./authService";

Axios.defaults.headers.common["authorization"] = `JWT ${auth.getToken()}`;

Axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) logger.log(error);
  return Promise.reject(error);
});

async function getLogList() {
  const x = await Axios.get(`${config.serverURLSSL}login_log/`);
  return x.data;
}

export default {
  getLogList,
};
