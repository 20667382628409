// import Raven from "raven-js";

// function init() {
//   Raven.config(
//     "https://51b96020de2445cab37ad48964c1a741@o431877.ingest.sentry.io/5383787",
//     {
//       release: "0.0.1",
//       environment: "dev",
//     }
//   ).install();
// }

// function log(error) {
//   Raven.captureException(error);
// }

// export default {
//   init,
//   log,
// };

import * as Sentry from "@sentry/browser";

function init() {
  Sentry.init({
    dsn:
      "https://51b96020de2445cab37ad48964c1a741@o431877.ingest.sentry.io/5383787",
    environment: "dev",
    release: "0.0.2",
    //defaultIntegrations: false,
    integrations(integrations) {
      return (
        integrations
          // Disable global error handler
          //.filter(i => i.name !== 'GlobalHandlers')

          // Disable other integrations
          .filter((i) => i.name !== "TryCatch")
      );
    },
  });
}

function log(error) {
  Sentry.captureException(error);
}

export default {
  init,
  log,
};
