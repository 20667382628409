import React from "react";
import { NavLink } from "react-router-dom";
import { Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import config from "../config.json";

const urlLength = `${config.serverURL}Users/`.length;

const usersCols = [
  {
    field: "Id",
    title: "Id",
    render: (rowData) => {
      return rowData.url.substring(urlLength, rowData.url.length - 1);
    },
  },
  { field: "username", title: "Username" },
  { field: "first_name", title: "First name" },
  { field: "last_name", title: "Last name" },
  { field: "email", title: "email" },
  {
    field: "date_joined",
    title: "Date joined",
    type: "date",
    editable: "never",
  },
  { field: "is_staff", title: "Staff" },
  { field: "is_superuser", title: "SuperUser" },
  { field: "is_active", title: "Active" },
];

const postsCols = [
  {
    field: "auth.first_name",
    title: "Author name",
    render: (rowData) =>
      rowData.auth ? (
        <NavLink
          to={{
            pathname: `/userView/${rowData.author_id}`,
            state: { user: rowData.auth },
          }}
        >
          {rowData.auth.first_name} {rowData.auth.last_name}
        </NavLink>
      ) : (
        <Typography variant="body1">{<Skeleton />}</Typography>
      ),
  },
  { field: "content", title: "Content" },
  { field: "media_type", title: "Media Type" },
  {
    field: "date_created",
    title: "Published on",
    type: "datetime",
    editable: "never",
  },
];

const articlesCols = [
  { field: "author_id", title: "Author Id" },
  {
    field: "title",
    title: "Title",
    cellStyle: { width: "40%", fontWeight: "bold" },
  },
  {
    field: "small_description",
    title: "Description",
    cellStyle: { width: "40%" },
  },
  { field: "keywords", title: "Domain" },
  { field: "files", title: "Joints", type: "numeric" },
  {
    field: "date_created",
    title: "Published",
    type: "date",
    editable: "never",
  },
];

const skillsCols = [{ field: "name", title: "Name" }];

const expCols = [
  { field: "company", title: "Company" },
  { field: "postion", title: "Position" },
  { field: "location", title: "Location" },
  { field: "from_date", title: "From data", type: "date" },
  { field: "to_date", title: "To", type: "date" },
];

const commentsArticleCols = [
  {
    title: "User",
    render: (rowData) =>
      rowData.auth ? (
        <NavLink
          to={{
            pathname: `/userView/${rowData.author_id}`,
            state: { user: rowData.auth },
          }}
        >
          {rowData.auth.first_name} {rowData.auth.last_name}
        </NavLink>
      ) : (
        <Typography variant="body1">{<Skeleton />}</Typography>
      ),
    editable: "never",
  },
  { field: "content", title: "Comment" },
  { field: "date_created", title: "Date", type: "datetime", editable: "never" },
];

const likesArticleCols = [
  {
    title: "User",
    render: (rowData) =>
      rowData.auth ? (
        <NavLink
          to={{
            pathname: `/userView/${rowData.author_id}`,
            state: { user: rowData.auth },
          }}
        >
          {rowData.auth.first_name} {rowData.auth.last_name}
        </NavLink>
      ) : (
        <Typography variant="body1">{<Skeleton />}</Typography>
      ),
    editable: "never",
  },
  { field: "date_created", title: "Date", type: "date", editable: "never" },
];

const languagesCols = [{ field: "name", title: "Language" }];

export default {
  usersCols,
  postsCols,
  articlesCols,
  skillsCols,
  expCols,
  languagesCols,
  commentsArticleCols,
  likesArticleCols,
};
